import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    private localStorage: any;
    public Keys = LocalStorageKeys;

    constructor() {
        this.localStorage = window.localStorage;
    }

  // tslint:disable-next-line:typedef
    save(key: any, object: string) {
        //// console.log(`save in storage key:${key} and object:`, object)
        let stringToSaveToStorage = '';
        if (typeof (object) === 'string') {
            stringToSaveToStorage = object;
        } else {
            stringToSaveToStorage = JSON.stringify(object);
        }
        this.localStorage.setItem(key, stringToSaveToStorage);
        let result = null;
        try {
            result = this.getAsObject(key);
        } catch (ex) {
            result = this.get(key);
        }
        return result;
    }

  // tslint:disable-next-line:typedef
    getAsObject(key: any) {
        let item = this.get(key);

        if (item && item != "") {
            let parsedItem = item;
            try {
                parsedItem = JSON.parse(item);
            } catch (e) {
            }
            return parsedItem;
        }

        return item;
    }

    get(key: any) {
        return this.localStorage.getItem(key);
    }

    delete(key: any) {
        this.localStorage.removeItem(key);
    }
}

export const LocalStorageKeys = {
    Languages: "Languages",
}
