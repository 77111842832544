
<section>
  <div class="container-page">
    <nav class="navbar navbar-expand navbar-light mb-3">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <img src="assets/image/logo.png" class="logo">
          </li>
        </ul>
        <div class="form-inline my-2 my-lg-0">
          <div class="box mt-4 mr-5">
            <li class="dropdown lang" style="list-style: none; ">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" >
                <img src="assets/image/{{translate.currentLang}}_flag.png">
              </a>
              <ul class="dropdown-menu">

                <li (click)="useLanguage('ru')" *ngIf="translate.currentLang!='ru'" class="lang_flag" lang="ru"><img src="assets/image/ru_flag.png" ></li>
                <li (click)="useLanguage('en')" *ngIf="translate.currentLang!='en'" class="lang_flag" lang="en"><img src="assets/image/en_flag.png" ></li>
                <li (click)="useLanguage('hy')"  *ngIf="translate.currentLang!='hy'" class="lang_flag" lang="am"><img src="../assets/image/hy_flag.png" ></li>

              </ul>
            </li>
          </div>
        </div>
      </div>
    </nav>
  </div>
</section>
<router-outlet></router-outlet>
<section class="footer">
  <div class="container-page">
    <div class="p-2 text-center">
      <a href="https://inmenu.am/#/privacy/?lng={{translate.currentLang}}" class="policy">Privacy Policy</a>
      <p class="mt-2 mb-0 copyright">© Copyright 2021 InMenu. All rights reserved. </p>
    </div>
  </div>
</section>
