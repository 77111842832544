
<section class="bg1">
  <div class="container-page-partner">
    <div class="row mt-5 part1 ">
      <div class="col-lg-12 col-xl-7 ">
        <div class=" pt-3 mt-5">
          <div class="first_title  pl-3">
          {{'title_1' | translate }}
          </div>


          <div class="mt-5 first_text" >
            <p class="pl-3">
            {{'p_1_1' | translate }}
            </p>

            <ul class="mt-3">
              <li>{{'li_1_1' | translate }}</li>
              <li>{{'li_1_2' | translate }}</li>
              <li>{{'li_1_3' | translate }}</li>
              <li>{{'li_1_4' | translate }}</li>
              <li>{{'li_1_5' | translate }}</li>
              <li>{{'li_1_6' | translate }}</li>
              <li>{{'li_1_7' | translate }}</li>
            </ul>

            <p class="pl-3">
            {{'p_1_2' | translate }}
            </p>
            <div class="mt-4">
              <a href="https://partnerapp.inmenu.am/Ksov/b989bb7">
                <img src="assets/image/app_store.png" class="market mr-2">
              </a>
              <a href="https://partnerapp.inmenu.am/Ksov/c7c03883">
                <img src="assets/image/play_market.png" class="market ">
              </a>
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-12 col-xl-5 bg11">
        <img src="assets/image/first_p.png" class="mobile_image">
      </div>


    </div>
  </div>
</section>
<section class="bg2">
  <div class="bg2_1">
    <div class="container-page-partner">
      <div class="row mt-5  page02" >

        <div class="col-lg-12 col-xl-4 image_content">
          <img src="assets/image/Group1162.png" class="mobile_image">
        </div>


        <div class="col-lg-12 col-xl-7">
          <div class="row">
            <div class="col-4 col-xl-12 cl4 number_item_center">
              <span class="numbers mr-18">02</span>
              <img src="assets/image/solid.png" class="border_green" alt="">
            </div>
            <div class="col-8 col-xl-12  item_center">
              <div class="partner_title_02 title ">
              {{'title_2' | translate }}
              </div>
            </div>
            <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p" >
              <div class="description_02 description " >
                <p>
                {{'p_2_1' | translate }}
                </p>
                <p class="mt-3">
                {{'p_2_2' | translate }}
                </p>
                <ul class="pl-4">
                  <li>{{'li_2_1' | translate }}</li>
                  <li>{{'li_2_2' | translate }}</li>
                  <li>{{'li_2_3' | translate }}</li>
                </ul>
              </div>
            </div>


          </div>
        </div>

        <div class="col-lg-12 col-xl-4 image_content1">
          <img src="assets/image/Group1162.png" class="mobile_image">
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section03">
  <div class="container-page-partner">
    <div class="row" >
      <div class="col-lg-12 col-xl-7">
        <div class="row page03">
          <div class="col-4 col-xl-12 cl4 number_item_center">
            <img src="assets/image/solid.png" class="border_green float-left">
            <span class="numbers ml-18">03</span>
          </div>
          <div class="col-8 col-xl-12 item_center">
            <div class="title title_03  pl-3">
            {{'title_3' | translate }}
            </div>
          </div>
          <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p">
            <div class="description_03 description" >
              <p class="pl-3 pr-3">
              {{'p_3_1' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xl-5 image_center">
        <img src="assets/image/pic_3.png" class="mobile_image">
      </div>
    </div>
  </div>
</section>
<section class="section04">
  <div class="container-page-partner">
    <div class=" row bg_color page04" >

      <div class="col-4 number_item_center">
        <span class="numbers mr-18">04</span>
        <img src="assets/image/solid.png" class="border_green" alt="">
      </div>

      <div class="col-8 item_center">
        <div class="partner_title_04 title ">
        {{'title_4' | translate }}
        </div>
      </div>
      <div class="offset-lg-4 col-lg-8 col-t2-p" >
        <p class="partner_description_04 description">
        {{'p_4_1' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container-page-partner">
    <div class="row bg_color mt-5 image_part">
      <div class="col-4 col-xl-4 ">
        <img src="assets/image/pic_4_1.png" style="width: 90%" class="mobile_image ml-5">
      </div>
      <div class="col-4 col-xl-4">
        <img src="assets/image/pic_4_2.png" class="mobile_image ml-5 for_margin_1">
      </div>
      <div class="col-4 col-xl-4 pr-5">
        <img src="assets/image/pic_4_3.png" class="mobile_image mr-5 for_margin_2">
      </div>

    </div>
  </div>
</section>
<section class="bg_5 section05">
  <div class="container-page-partner">
    <div class="row mt-5  page05"  >


      <div class="col-lg-12 col-xl-5  image_content">
        <img src="assets/image/pic_5_2.png" class="mobile_image">
      </div>

      <div class="col-lg-12 col-xl-7">
        <div class="row child-container">
          <div class="col-4 col-xl-12 cl4 number_item_center">
            <img src="assets/image/solid.png" class="border_green float-left" alt="">
            <span class="numbers ml-18">05</span>
          </div>
          <div class="col-8 col-xl-12 item_center">
            <div class="partner_title_05  title">
            {{'title_5' | translate }}
            </div>
          </div>
          <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p">
            <div class="partner_description_05 description " >
              <p>
              {{'p_5_1' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-xl-5  text-center image_content1">
        <img src="assets/image/pic_5_2.png" class="mobile_image">
      </div>

    </div>
  </div>
</section>
<section class="section06 bg_color">
  <div class="bg_6">
    <div class="container-page-partner">
      <div class="row  page06 ">

        <div class="col-lg-12 col-xl-7">

          <div class="row child-container">
            <div class="col-4 col-xl-12 cl4 number_item_center">
              <img src="assets/image/solid.png" class="border_green float-left" alt="">
              <span class="numbers ml-18">06</span>
            </div>
            <div class="col-8 col-xl-12 item_center">
              <div class="partner_title_06 title  pl-3">
              {{'title_6' | translate }}
              </div>
            </div>
            <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p">
              <div class="partner_description_06 description">
                <p class="pl-3 pr-3">
                {{'p_6_1' | translate }}
                </p>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-12 col-xl-5 bg_6 image_center"  >
          <img src="assets/image/pic_6_2.png" class="mobile_image">
        </div>

      </div>
    </div>
  </div>
</section>

<section class="section_contact">
  <div class="container-page-partner">
    <div class="row bg_color last_row">
      <div class="col-xl-12 col-xxl-6">
        <div class="contact margin-5 text-center question">
          <span class="finish pt-5"><b>{{'have_question' | translate }}    </b></span>
          <span class="speak">{{'talk' | translate }}</span>
          <div class="p-5">
            <form method="post" action="mail.php" class="mb-5">
              <div class="form-group">
                <input type="text" name="name" placeholder="{{'name' | translate }}" class="input ">
              </div>
              <div class="form-group">
                <input type="text" name="email" placeholder="{{'email' | translate }}" class="input ">
              </div>
              <div class="form-group">
                <input type="text" name="question" placeholder="{{'question' | translate }}" class="input input-p2">
              </div>
              <div class="form-group">
                <button class=" submit-button">{{'submit' | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-12 col-xxl-6 feed">
        <img src="assets/image/solid.png"  class="float-left border_green">
        <div class="ml-7">
          <p class="feedback">{{'feedback' | translate }}</p>
          <span class="phone">{{'phone'| translate}} 055-46-63-68</span>
          <p class="infoMail">info@inmenu.am</p>
          <div>
            <a target="_blank" href="https://www.facebook.com/InMenuArmenia"><img src="assets/image/facebook.png" alt=""></a>
            <a target="_blank" href="https://www.youtube.com/channel/UCnJ-EFoMn412MnjNxAEAtzA" class="ml-1"><img src="assets/image/youtube.png" alt=""></a>
            <a target="_blank" href="https://www.instagram.com/Inmenuarmenia/" class="ml-1"><img src="assets/image/instagram.png" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
