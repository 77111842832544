import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {StorageService} from './shared/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public translate: TranslateService,public activeRoute:ActivatedRoute, private router: Router,private storage:StorageService) {
    translate.setDefaultLang('en');
    this.activeRoute.queryParams
      .subscribe(params => {
      this.useLanguage(params.lng||this.storage.get(this.storage.Keys.Languages)||translate.getBrowserLang() || translate.getDefaultLang());
    });
  }

  title = 'InMenu Partner';


  useLanguage(language: string) {
    this.translate.use(language);
    this.storage.save(this.storage.Keys.Languages,language);

  }

}
